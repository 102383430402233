import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

const CardWithBackground = ({ image, headline, background, children }) => (
  <div className="h-full text-dark-blue md:relative md:flex md:flex-col">
    <BackgroundImage
      fluid={image.childImageSharp.fluid}
      className="image-behind-card"
    />
    <div
      className={`bg-${background} children-margin h-full p-10 md:relative md:z-10 md:mt-40 md:ml-16`}
    >
      <h3 dangerouslySetInnerHTML={{ __html: headline }}></h3>
      {children}
    </div>
  </div>
);

CardWithBackground.propTypes = {
  image: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default CardWithBackground;

import React from 'react';
import PropTypes from 'prop-types';

const CardHeader = ({
  headline,
  parallel,
  centered,
  color,
  smallFontSize,
  children
}) => {
  let classNames = 'card-header';

  if (parallel) {
    classNames += ' card-header--parallel';
  }

  if (centered) {
    classNames += ' text-center center-icon';
  }

  if (color) {
    classNames += ` text-${color}`;
  }

  return (
    <div className={classNames}>
      {children}
      {headline && (
        <h3 className={smallFontSize ? 'text-xl' : null}>{headline}</h3>
      )}
    </div>
  );
};

CardHeader.propTypes = {
  headline: PropTypes.string,
  parallel: PropTypes.bool,
  centered: PropTypes.bool,
  color: PropTypes.string,
  smallFontSize: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default CardHeader;

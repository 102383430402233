import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  ContentBox,
  Button,
  HalvedContent,
  Grid,
  List
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz';
import subpageNavigations from 'src/data/subpage-navigations';

const BetrieblicherEinkommensschutzPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: "75"
    }}
    subpageNavItems={subpageNavigations.absicherungFuerMitarbeiter}
    activeNavItem={2}
    useParentPathname
  >
    <section>
      <SectionHead headline={content.sectionHead.headline} centered>
        <p>{content.sectionHead.paragraph}</p>
      </SectionHead>
      <HalvedContent swapped>
        <BackgroundImage fluid={data.cardBackground1.childImageSharp.fluid} />
        <ContentBox
          background={content.imageContent.backgroundColor}
          topline={content.imageContent.topline}
          headline={content.imageContent.headline}
          align="right"
        >
          <p>{content.imageContent.paragraph}</p>
          <Button href={content.imageContent.button.link} variant="peach">
            {content.imageContent.button.name}
          </Button>
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <SectionHead
        topline={content.grundfaehigkeitsversicherung.sectionHead.topline}
        headline={content.grundfaehigkeitsversicherung.sectionHead.headline}
        centered
      >
        <p>{content.grundfaehigkeitsversicherung.sectionHead.paragraph}</p>
      </SectionHead>
      <Grid>
        {content.grundfaehigkeitsversicherung.cards.map((card, index) => (
          <Card key={index} centered>
            <CardHeader headline={card.title} color="dark-blue">
              <card.icon />
            </CardHeader>
            <Button href={card.href} variant="gray-blue" fullWidth>
              {card.buttonText}
            </Button>
          </Card>
        ))}
      </Grid>
      <div className="button-wrap">
        <Button
          href={content.grundfaehigkeitsversicherung.button.href}
          variant={content.grundfaehigkeitsversicherung.button.variant}
        >
          {content.grundfaehigkeitsversicherung.button.name}
        </Button>
      </div>
    </section>
    <BackgroundImage
      fluid={data.emptyOffice.childImageSharp.fluid}
      className="full-width-image bg-bottom"
    />
    <section>
      <ContentBox
        headline={content.vorteile.headline}
        background="light-blue"
        single
        shifted
      >
        <List items={content.vorteile.list} columns={2} />
      </ContentBox>
    </section>
  </MainLayout>
);

export default BetrieblicherEinkommensschutzPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "group-of-five-people-wide.jpg" }
    ) {
      ...image
    }
    cardBackground1: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "young-stylish-buisiness-man.jpg" }
    ) {
      ...image
    }
    emptyOffice: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "empty-office.jpg" }
    ) {
      ...image
    }
  }
`;

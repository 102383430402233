import React from 'react';
import PropTypes from 'prop-types';

import lightBackgrounds from 'src/data/light-backgrounds';

const Card = ({ href, background, centered, noShadow, children }) => {
  let classNames = `card children-margin h-full p-10 ${
    background ? `bg-${background}` : 'bg-white'
  }`;

  if (background && !lightBackgrounds.includes(background)) {
    classNames += ' text-white';
  }

  if (centered) {
    classNames += ' text-center center-icon';
  }

  if (!noShadow) {
    classNames += ' shadow-md';
  }

  if (href) {
    return (
      <a
        href={href}
        className={`block ${classNames} transition-colors duration-300 hover:bg-dark-blue hover:text-white`}
      >
        {children}
      </a>
    );
  }

  return <div className={classNames}>{children}</div>;
};

Card.propTypes = {
  href: PropTypes.string,
  background: PropTypes.string,
  centered: PropTypes.bool,
  shadow: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default Card;

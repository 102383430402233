import HandcartIcon from 'src/assets/icons/handcart.svg';
import SafetyHatIcon from 'src/assets/icons/safety-hat.svg';
import HeartPlusIcon from 'src/assets/icons/heart-plus.svg';
import CarServiceIcon from 'src/assets/icons/car-service.svg';

const content = {
  title: 'Betrieblicher Einkommensschutz',
  headvisualHeadline: 'Betrieblicher Einkommensschutz',
  sectionHead: {
    headline: 'Schützen Sie die Existenz Ihrer Mitarbeiter',
    paragraph:
      'Ihr Mitarbeiter steht am Anfang seines Berufslebens oder ist schon mittendrin? Dann ist die Arbeitskraft sein größtes Kapital. Es ermöglicht ihm ein regelmäßiges Einkommen und sichert den Lebensstandard. Doch was passiert eigentlich, wenn er krankheits- oder unfallbedingt nicht mehr arbeiten kann? Da kann es finanziell auch mal eng werden. Aus diesem Grund ist es wichtig rechtzeitig vorzusorgen.'
  },
  imageContent: {
    topline: 'Betrieblicher Einkommensschutz',
    headline: 'Berufsunfähigkeitsversicherung.',
    paragraph:
      'Jeder 4. Erwerbstätige scheidet vor Erreichen des Rentenalters wegen Krankheit oder Unfall aus dem aktiven Berufsleben aus.  Mit einer Berufsunfähigkeitsversicherung können Sie das Einkommen Ihrer Kunden schützen und für finanziellen Spielraum sorgen.',
    backgroundColor: 'dark-blue',
    button: {
      name: 'Zur Berufsunfähigkeitsversicherung',
      link:
        '/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/eks-berufsunfaehigkeit'
    }
  },
  grundfaehigkeitsversicherung: {
    sectionHead: {
      topline: 'Betrieblicher Einkommensschutz',
      headline: 'Grundfähigkeitsversicherung.',
      paragraph:
        'Für Menschen, die anpacken: Körperlich Tätige haben ein höheres Risiko, durch den Verlust einer Grundfähigkeit vorzeitig aus dem Arbeitsleben auszuscheiden. Eine Absicherung über eine reguläre BU ist in diesen Fällen meist unerschwinglich. Mit einer Absicherung der Grundfähigkeiten können Sie hier den passenden Schutz bieten.'
    },
    cards: [
      {
        title: 'Highlights für Spediteure',
        icon: HandcartIcon,
        href:
          '/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/gfv-highlights#spediteure',
        buttonText: 'Mehr erfahren'
      },
      {
        title: 'Highlights für Handwerker',
        icon: SafetyHatIcon,
        href:
          '/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/gfv-highlights#handwerker',
        buttonText: 'Mehr erfahren'
      },
      {
        title: 'Highlights für das Gesundheitswesen',
        icon: HeartPlusIcon,
        href:
          '/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/gfv-highlights#gesundheitswesen',
        buttonText: 'Mehr erfahren'
      },
      {
        title: 'Highlights für das KFZ-Handwerk',
        icon: CarServiceIcon,
        href:
          '/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/gfv-highlights#kfz-handwerk',
        buttonText: 'Mehr erfahren'
      }
    ],
    button: {
      name: 'Zur Grundfähigkeitsversicherung',
      variant: 'coral',
      href:
        '/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/eks-grundfaehigkeitsversicherung'
    }
  },
  vorteile: {
    headline: 'Vorteile für Sie:',
    list: [
      'Mit betrieblichen Vorsorgemodellen als Zusatzleistung steigern die Arbeitgeber ihre Attraktivität und haben bessere Chancen bei der Suche nach Fach- und Führungskräften',
      'Sie erhöhen die Loyalität ihrer Mitarbeiter und senken die Fluktuation',
      'Ihre Mitarbeiter erfahren durch Sie eine besondere Wertschätzung, sind motivierter und zufriedener',
      'Sie zeigen sich als verantwortungsvoll und ermöglichen ihren Mitarbeitern eine umfassende Absicherung',
      'Die bAV-Verträge können einfach mit digitalen bAV-Portalen online verwaltet werden, ohne großen Aufwand für Sie',
      'Die NÜRNBERGER unterstützt Ihre Mitarbeiter finanziell bei Reha-Maßnahmen, um eine schnellere Rückkehr in das Berufsleben zu ermöglichen',
      'Ihre Beiträge sind voll als Betriebsausgaben absetzbar'
    ]
  }
};

export default content;

import CashInHandIcon from 'src/assets/icons/cash-in-hand.svg';
import ElderlyPersonIcon from 'src/assets/icons/elderly-person.svg';
import OpenFolderIcon from 'src/assets/icons/open-folder.svg';
import ReceiveCashIcon from 'src/assets/icons/receive-cash.svg';
import SearchMoreIcon from 'src/assets/icons/search-more.svg';
import SickIcon from 'src/assets/icons/sick.svg';
import VolunteeringIcon from 'src/assets/icons/volunteering.svg';
import NewspaperIcon from 'src/assets/icons/newspaper.svg';
import BookOpenIcon from 'src/assets/icons/book-open.svg';
import OpenTextIcon from 'src/assets/icons/open-text.svg';

const aktuelles = [
  {
    href: '/newsroom',
    icon: NewspaperIcon,
    name: 'Newsroom'
  },
  {
    href: '/ratgeber',
    icon: BookOpenIcon,
    name: 'Ratgeber'
  },
  {
    href: '/newsletter',
    icon: OpenTextIcon,
    name: 'Newsletter'
  }
];

const absicherungFuerLeitendeAngestellte = [
  {
    href: '/entgeltumwandlung-laufende-beitraege#direktversicherung',
    icon: ReceiveCashIcon,
    name: 'Basis-Absicherung'
  },
  {
    href: '/entgeltumwandlung-laufende-beitraege#unterstuetzungskasse',
    icon: ReceiveCashIcon,
    name: 'Comfort-Absicherung'
  },
  {
    href: '/entgeltumwandlung-laufende-beitraege#einmalbeitraege',
    icon: ReceiveCashIcon,
    name: 'Premium-Absicherung'
  }
];

const absicherungFuerMitarbeiter = [
  {
    href: '/betriebliche-altersvorsorge',
    icon: ElderlyPersonIcon,
    name: 'Betriebliche\nAltersvorsorge'
  },
  {
    href: '/betrieblicher-einkommensschutz',
    icon: ReceiveCashIcon,
    name: 'Betrieblicher\nEinkommensschutz'
  },
  {
    href: '/betriebliche-krankenversicherung',
    icon: SickIcon,
    name: 'Betriebliche\nKrankenversicherung'
  },
  {
    href: '/weitere-absicherungsmoeglichkeiten',
    icon: SearchMoreIcon,
    name: 'Weitere\nAbsicherungsmöglichkeiten'
  }
];

const betrieblicheAltersvorsorge = [
  {
    href: '/bav-direktversicherung',
    icon: ReceiveCashIcon,
    name: 'Direktversicherung'
  },
  {
    href: '/bav-unterstuetzungskasse',
    icon: ReceiveCashIcon,
    name: 'Unterstützungskasse'
  },
  {
    href: '/bav-pensionszusage',
    icon: ReceiveCashIcon,
    name: 'Pensionszusage'
  },
  {
    href: '/bav-pensionsfonds',
    icon: ReceiveCashIcon,
    name: 'Pensionsfonds'
  }
];

const betrieblicherEinkommensschutz = [
  {
    href: '/eks-berufsunfaehigkeit',
    icon: SickIcon,
    name: 'Berufsunfähigkeits-\nversicherung'
  },
  {
    href: '/eks-grundfaehigkeitsversicherung',
    icon: VolunteeringIcon,
    name: 'Grundfähigkeits-\nversicherung'
  },
  {
    href: '/gfv-highlights',
    icon: VolunteeringIcon,
    name: 'GFV\nHighlights'
  }
];

const beratungUndBetreuung = [
  {
    href: '/xbav-und-epension',
    icon: CashInHandIcon,
    name: 'XEMPUS & ePension'
  },
  {
    href: '/betriebsrenten-portal',
    icon: CashInHandIcon,
    name: 'Betriebsrenten-Portal'
  }
];

const mediathek = [
  {
    href: encodeURI('/bav-beratung und -betreuung'),
    icon: OpenFolderIcon,
    name: 'bAV-Beratung und -Betreuung'
  },
  {
    href: '/dienstleistungen',
    icon: OpenFolderIcon,
    name: 'Dienstleistungen'
  },
  {
    href: encodeURI('/lösungen'),
    icon: OpenFolderIcon,
    name: 'Lösungen'
  },
  {
    href: '/produkte',
    icon: OpenFolderIcon,
    name: 'Produkte'
  }
];

const subpageNavigations = {
  aktuelles,
  absicherungFuerLeitendeAngestellte,
  absicherungFuerMitarbeiter,
  betrieblicheAltersvorsorge,
  betrieblicherEinkommensschutz,
  beratungUndBetreuung,
  mediathek
};

export default subpageNavigations;
